<p><strong>Forgot password</strong></p>
<div class="alert alert-success" *ngIf="success">
  <strong>Success</strong><br>Link sent
</div>
<form [formGroup]="form" (ngSubmit)="login()">
  <div class="form-group" *ngIf="!success">
    <label>Email:</label>
    <input type="email" class="form-control full-width" formControlName="email" />
    <div *ngIf="form.controls['email'].invalid && (form.controls['email'].dirty || form.controls['email'].touched)" class="control-error">
      Invalid email
    </div>
  </div>
  <div class="form-group">
    <button class="btn btn-primary btn-full-width" [disabled]="form.invalid"  *ngIf="!success">Send mail</button><br />
    <a [routerLink]="[ '/auth/login'] ">Back</a>
  </div>
</form>