<h2>Redirection</h2>

<div class="alert alert-danger" *ngIf="response !== undefined && !response.success">
    <strong>Error:</strong><br />
    {{response | json}}
</div>

<form [formGroup]="form" (submit)="submit()">
    <div class="form-group">
      <label>Name:</label>
      <input type="text" class="form-control full-width" formControlName="name" />
    </div>
    <div class="form-group">
      <label>Code:</label>
      <input type="text" class="form-control full-width" formControlName="code" />
    </div>
    <div class="form-group">
      <label>Target:</label>
      <input type="text" class="form-control full-width" formControlName="target" />
    </div>

    <div class="form-group">
        <button class="btn btn-success" type="submit">Create</button>&nbsp;
        <div class="btn btn-danger" (click)="close()">Close</div>
      </div>
</form>