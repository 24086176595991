import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoginRequest } from './login';
import { ObjectResponse } from 'src/app/models/ObjectResponse';
import { LoginResponse } from './authresponse';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private httpClient: HttpClient, private router: Router) {}

  public GetToken(): string {
    return localStorage.getItem('token');
  }

  public isAuthenticated(): boolean {
    const helper = new JwtHelperService();

    const token = localStorage.getItem('token');
    const validToken = !helper.isTokenExpired(token);

    if (!validToken) {
      localStorage.removeItem('token');
    }

    return validToken;
  }

  public getId() {
    const helper = new JwtHelperService();
    const token = helper.decodeToken(this.GetToken());

    return token.id;
  }

  public isResourceAvailable() {
    const helper = new JwtHelperService();
    const token = helper.decodeToken(this.GetToken());

    return token.resourceId != null;
  }

  public login(username: string, password: string, twoToken: string): Observable<number> {
    const test = new LoginRequest();
    test.userName = username;
    test.password = password;
    test.token = twoToken;
    return Observable.create(observer => {
      this.httpClient.post<ObjectResponse<LoginResponse>>('/api/v1/Auth/Login', test)
      .subscribe(response => {
        if (response.data.authToken == null && response.data.twoFaRequired) {
          return observer.next(2);
        } else if (response.data.authToken != null) {
          localStorage.setItem('token', response.data.authToken);

          observer.next(1);
        } else {
          observer.next(0);
        }
      }, error => {
        observer.next(0);
      });
    });
  }

  public logout() {
    localStorage.removeItem('token');
    this.router.navigate([ '/auth/login' ]);
  }
}
