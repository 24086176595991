import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormGroupDirective, ReactiveFormsModule } from '@angular/forms';
import { AuthAnonGuardService } from './shared/auth/auth-anon.service';
import { AuthGuardService } from './shared/auth/auth-guard.service';
import { TokenInterceptorService } from './shared/auth/token-interceptor.service';
import { LoginComponent } from './modules/login/login.component';
import { ForgotPasswordComponent } from './modules/forgot-password/components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './modules/forgot-password/components/reset-password/reset-password.component';
import { UnauthenticatedComponent } from './shared/components/unauthenticated/unauthenticated.component';
import { SideMenuComponent } from './shared/components/loggedin/components/side-menu/side-menu.component';
import { LoggedinComponent } from './shared/components/loggedin/loggedin.component';
import { PaginationComponent } from './shared/components/pagination/pagination.component';
import { DomainListComponent } from './modules/feature/domains/components/domain-list/domain-list.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatTabsModule} from '@angular/material/tabs';
import { DomainCreateDialogComponent } from './modules/feature/domains/modals/domain-create-dialog/domain-create-dialog.component';
import { DomainDetailComponent } from './modules/feature/domains/components/domain-detail/domain-detail.component';
import { RouterModule } from '@angular/router';
import { RedirectionCreateDialogComponent } from './modules/feature/domains/modals/redirection-create-dialog/redirection-create-dialog.component';


@NgModule({
  declarations: [
    AppComponent,
    LoggedinComponent,
    LoginComponent,
    UnauthenticatedComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    SideMenuComponent,
    PaginationComponent,
    DomainListComponent,
    DomainCreateDialogComponent,
    DomainDetailComponent,
    RedirectionCreateDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatTabsModule,
    MatSnackBarModule,
    HttpClientModule,
    BrowserAnimationsModule,
    RouterModule
  ],
  providers: [
    AuthGuardService,
    AuthAnonGuardService,
    FormGroupDirective,
    {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
