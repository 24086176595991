import { Component, OnInit } from '@angular/core';
import { AuthHttpService } from '../../services/auth-http.service';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators, UntypedFormBuilder, ValidatorFn, ValidationErrors, AbstractControl } from '@angular/forms';
import { Response } from '../../../../models/Response';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  found = false;
  email: string;
  token: string;
  loading = true;

  error: Response;
  success: boolean;
  submitted: boolean;

  form: UntypedFormGroup;


  constructor(private authService: AuthHttpService, private route: ActivatedRoute, private formBuilder: UntypedFormBuilder) {
    this.form = this.formBuilder.group({
      password: new UntypedFormControl('', Validators.required),
      passwordConf: new UntypedFormControl('', Validators.required),
    });
    this.form.setValidators(this.comparisonValidator());

    this.route.queryParams.subscribe(params => {
        this.email = params.email;
        this.token = params.token;

        if (this.email === null || this.token === null) {
          this.found = false;
          this.loading = false;
        } else {
          this.authService.verifyResetToken(this.email, this.token).subscribe((success) => {
            this.loading = false;
            this.found = true;
          }, (error) => {
            this.loading = false;
            this.found = false;
          });
        }
    });
   }

   public comparisonValidator(): ValidatorFn {
    return (group: UntypedFormGroup): ValidationErrors => {
      const control1 = group.controls.password;
      const control2 = group.controls.passwordConf;
      if (control1.value !== control2.value) {
         control2.setErrors({notsame: true});
      } else {
         control2.setErrors(null);
      }
      return;
    };
  }

  ngOnInit() {
  }

  mapResponse() {
    if (!this.error) {
      return;
    }

    this.error.errors.forEach((message) => {

      const error = {};
      error[message.code] = true;

      this.form.controls[message.field].setErrors(error);
    });
  }

  reset() {
    if (this.form.invalid) {
      return;
    }


    this.submitted = true;

    this.authService.ResetPassword(this.email, this.token, this.form.controls.password.value).subscribe((success) => {
      this.error = null;
      this.success = true;
    }, (error) => {
      this.error = error.error;

      if (this.error.code === 'NOT_FOUND') {
        this.found = false;
      } else {
        this.mapResponse();
        this.success = false;
      }
    });
  }
}
