import { Component, OnInit } from '@angular/core';
import { Form, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ObjectResponse } from 'src/app/models/ObjectResponse';
import { Domain } from '../../models/domain';
import { DomainHttpService } from '../../services/domain-http-service';

@Component({
  selector: 'app-domain-create-dialog',
  templateUrl: './domain-create-dialog.component.html',
  styleUrls: ['./domain-create-dialog.component.scss']
})
export class DomainCreateDialogComponent implements OnInit {
  response: ObjectResponse<Domain>;

  form = new UntypedFormGroup({
    hostname: new UntypedFormControl('')
  });

  constructor(private dialog: MatDialogRef<DomainCreateDialogComponent>,
    private service: DomainHttpService,
    private router: Router) { }

  ngOnInit(): void {
  }

  submit()
  {
    this.service.create(this.form.controls.hostname.value).subscribe((success) => {
      this.router.navigate([ '/domain', success.data.id ]);
      this.dialog.close();
    }, (error) => {
      this.response = error;
    });

    return false;
  }

  close()
  {
    this.dialog.close();
  }

}
