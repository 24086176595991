<h2>Create domain</h2>

<div class="alert alert-danger" *ngIf="response !== undefined && !response.success">
    <strong>Error:</strong><br />
    {{response | json}}
</div>

<form [formGroup]="form" (submit)="submit()">
    <div class="form-group">
      <label>Hostname:</label>
      <input type="text" class="form-control full-width" formControlName="hostname" />
    </div>

    <div class="form-group">
        <button type="submit" class="btn btn-success">Create</button>&nbsp;
        <div class="btn btn-danger" (click)="close()">Close</div>
      </div>
</form>