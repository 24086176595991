<ng-container *ngIf="response !== undefined">
    <h2>Domain: {{response.data.hostname}}</h2>
    <strong>CName (click to copy):</strong> <pre (click)="copyCName()" style="cursor: pointer;">{{response.data.cname}}</pre>
    <strong>Verified:</strong> <pre>{{response.data.verified}}</pre>
    <button class="btn btn-success" (click)="validate()" [disabled]="response.data.verified">Validate</button>&nbsp;
    <div class="btn btn-danger" (click)="del()">Delete</div><br />
    <br />
    <h2>Redirections</h2>
    <div class="btn btn-success" (click)="createRedirection()">Create redirection</div><br /><br />
    <ng-container *ngIf="redirectionResponse !== undefined">
        <div class="table-responsive">
            <table class="table table-striped table-sm">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Code</th>
                  <th>Target</th>
                  <th>Link (click to copy)</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of redirectionResponse.data" (click)="editRedirection(item)" style="cursor: pointer;">
                  <td>{{item.id}}</td>
                  <td>{{item.name}}</td>
                  <td>{{item.code}}</td>
                  <td>{{item.target}}</td>
                  <td (click)="copyUrl(item.code);$event.stopPropagation()">https://{{response.data.hostname}}/{{item.code}}</td>
                  <td><i class="fas fa-trash-alt" (click)="deleteRedirection(item.id);$event.stopPropagation()"></i></td>
                </tr>
              </tbody>
            </table>
          </div>
          <app-pagination #paging [currentOffset]="redirectionResponse.skip" [total]="redirectionResponse.total" [pageSize]="pageSize" (onSelect)="navigateRedirection($event)"></app-pagination>
</ng-container>
