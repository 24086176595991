import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ListResponse } from 'src/app/models/list-response';
import { DomainCreateDialogComponent } from '../../modals/domain-create-dialog/domain-create-dialog.component';
import { Domain } from '../../models/domain';
import { DomainHttpService } from '../../services/domain-http-service';

@Component({
  selector: 'app-domain-list',
  templateUrl: './domain-list.component.html',
  styleUrls: ['./domain-list.component.scss']
})
export class DomainListComponent implements OnInit {
  response: ListResponse<Domain>;
  @ViewChild('paging') paging;
  pageSize = 20;
  pageNum: number;

  constructor(private service: DomainHttpService,
    private router: Router,
    private dialog: MatDialog,
    private route: ActivatedRoute) { 
    this.route.queryParams.subscribe(params => {
        this.pageNum = +params.page || 0;
    });}

  ngOnInit() {
    this.load();
  }

  goTo(id: number)
  {
    this.router.navigate(["/domain", id]);
  }

  create()
  {
    this.dialog.open(DomainCreateDialogComponent, {
      width: "600px"
    });
  }

  navigate(pageNum: number) {
    this.pageNum = pageNum;

    this.service.list(this.pageSize, this.pageNum * this.pageSize).subscribe((success) => {
      this.response = success;
      this.router.navigate(['.'], { relativeTo: this.route, queryParams: { page: pageNum }});
      this.paging.setPageNum(pageNum * this.pageSize);
    }, (error) => {
      // We should error here
    });
  }

  load() {
    this.service.list(this.pageSize, this.pageNum * this.pageSize).subscribe((success) => {
      this.response = success;
    }, (error) => {});
  }

}
