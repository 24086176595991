import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Response } from 'src/app/models/Response';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AuthHttpService {
  constructor(private httpClient: HttpClient) {}

  requestToken(email: string): Observable<Response> {
    return this.httpClient.post<Response>('/api/v1/Auth/request-reset', { email });
  }

  verifyResetToken(email: string, token: string): Observable<Response> {
    return this.httpClient.post<Response>('/api/v1/Auth/verify-reset-token', { email,  token });
  }

  ResetPassword(email: string, token: string, password: string): Observable<Response> {
    return this.httpClient.post<Response>('/api/v1/Auth/reset-password', { email, token, password });
  }
}
