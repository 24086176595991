import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './shared/auth/auth-guard.service';
import { LoggedinComponent } from './shared/components/loggedin/loggedin.component';
import { AuthAnonGuardService } from './shared/auth/auth-anon.service';
import { UnauthenticatedComponent } from './shared/components/unauthenticated/unauthenticated.component';
import { LoginComponent } from './modules/login/login.component';
import { ForgotPasswordComponent } from './modules/forgot-password/components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './modules/forgot-password/components/reset-password/reset-password.component';
import { DomainListComponent } from './modules/feature/domains/components/domain-list/domain-list.component';
import { DomainDetailComponent } from './modules/feature/domains/components/domain-detail/domain-detail.component';

const routes: Routes = [
  {
    path: '',
    component: LoggedinComponent,
    canActivate: [ AuthGuardService ],
    children: [
      {
        path: '',
        component: DomainListComponent,
        canActivate: [ AuthGuardService ]
      },
      {
        path: 'domain/:id',
        component: DomainDetailComponent,
        canActivate: [ AuthGuardService ]
      },
    ]
  },
  {
    path: 'auth',
    component: UnauthenticatedComponent,
    canActivate: [ AuthAnonGuardService ],
    children: [
      {
        path: 'login',
        component: LoginComponent,
        canActivate: [ AuthAnonGuardService ]
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        canActivate: [ AuthAnonGuardService ]
      },
      {
        path: 'reset-password',
        component: ResetPasswordComponent,
        canActivate: [ AuthAnonGuardService ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
