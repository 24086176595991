import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ListResponse } from 'src/app/models/list-response';
import { ObjectResponse } from 'src/app/models/ObjectResponse';
import { RedirectionCreateDialogComponent } from '../../modals/redirection-create-dialog/redirection-create-dialog.component';
import { Domain } from '../../models/domain';
import { RedirectionModel } from '../../models/Redirection';
import { DomainHttpService } from '../../services/domain-http-service';
import { RedirectionHttpService } from '../../services/redirection-http-service';

@Component({
  selector: 'app-domain-detail',
  templateUrl: './domain-detail.component.html',
  styleUrls: ['./domain-detail.component.scss']
})
export class DomainDetailComponent implements OnInit {
  response: ObjectResponse<Domain>;
  redirectionResponse: ListResponse<RedirectionModel>;
  id: number;
  pageNum: number;
  pageSize = 20;
  @ViewChild('paging') paging;

  constructor(
    private domainService: DomainHttpService,
    private router: Router,
    private dialog: MatDialog,
    private redirectionService: RedirectionHttpService,
    private snackbar: MatSnackBar,
    private route: ActivatedRoute) { 
      this.route.queryParams.subscribe(params => {
        this.pageNum = +params.page || 0;
      });

    this.route.params.subscribe(params => {
      this.domainService.get(params.id).subscribe((success) => {
        this.id = params.id;
        this.response = success;
        this.load();
      })
    });
  }

  ngOnInit(): void {
  }

  createRedirection()
  {
    this.dialog.open(RedirectionCreateDialogComponent, {
      data: { id: this.id },
      width: "600px"
    }).afterClosed().subscribe((data) =>
    {
      this.load();
    });
  }
 
  copyUrl(code: string) {
    this.copyString(`https://${this.response.data.hostname}/${code}`);

    this.snackbar.open(`Url 'https://${this.response.data.hostname}/${code}' copied to clipboard.`, "", {
      duration: 3000
    });
  }

  copyCName()
  {
    this.copyString(this.response.data.cname);

    this.snackbar.open(`'${this.response.data.cname}' copied to clipboard.`, "", {
      duration: 3000
    });
  }

  copyString(value: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = value;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  deleteRedirection(id: number)
  {
    if(!confirm("Are you sure you want to delete this redirection?"))
    {
      return;
    }

    this.redirectionService.delete(this.id, id).subscribe((success) => {
      this.snackbar.open("Redirection deleted", '', {
        duration: 3000
      });
      this.load();
    });
  }

  editRedirection(redirection: RedirectionModel)
  {
    this.dialog.open(RedirectionCreateDialogComponent, {
      data: { 
        id: this.id,
        redirection
      },
      width: "600px"
    }).afterClosed().subscribe((data) =>
    {
      this.load();
    });
  }

  navigateRedirection(pageNum: number) {
    this.pageNum = pageNum;

    this.redirectionService.list(this.id, this.pageSize, this.pageNum * this.pageSize).subscribe((success) => {
      this.redirectionResponse = success;
      this.router.navigate(['.'], { relativeTo: this.route, queryParams: { page: pageNum }});
      this.paging.setPageNum(pageNum * this.pageSize);
    }, (error) => {
      // We should error here
    });
  }

  load() {
    this.redirectionService.list(this.id, this.pageSize, this.pageNum * this.pageSize).subscribe((success) => {
      this.redirectionResponse = success;
    }, (error) => {});
  }

  validate()
  {
    this.domainService.validate(this.id).subscribe((success) => {
      this.snackbar.open("Domain verified", '', {
        duration: 3000
      });
      this.response.data.verified = true;
    }, (error) => {
      this.snackbar.open(`Error while validating: ${JSON.stringify(error)}`, '', {
        duration: 3000
      });
    });
  }

  del()
  {
    if(!confirm("Are you sure you want to delete this domain?"))
    {
      return;
    }

    this.domainService.delete(this.id).subscribe((success) => {
      this.snackbar.open("Domain deleted", '', {
        duration: 3000
      });
      this.router.navigate([ '/' ]);
    }, (error) => {
      this.snackbar.open(`Could not delete domain: ${JSON.stringify(error)}`, '', {
        duration: 3000
      });
    });
  }
}
