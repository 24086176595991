import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ObjectResponse } from 'src/app/models/ObjectResponse';
import { Domain } from '../models/domain';
import { ListResponse } from 'src/app/models/list-response';

@Injectable({
  providedIn: 'root',
})
export class DomainHttpService {
  constructor(private httpClient: HttpClient) {}

  list(take, skip): Observable<ListResponse<Domain>> {
    return this.httpClient.get<ListResponse<Domain>>(`/api/v1/domain?take=${take}&skip=${skip}`);
  }

  get(id: number): Observable<ObjectResponse<Domain>> {
    return this.httpClient.get<ObjectResponse<Domain>>(`/api/v1/domain/${id}`, { });
  }
  
  delete(id: number): Observable<Response> {
    return this.httpClient.delete<Response>(`/api/v1/domain/${id}`, { });
  }

  validate(id: number): Observable<Response> {
    return this.httpClient.post<Response>(`/api/v1/domain/${id}/validate`, { });
  }

  create(hostname: string): Observable<ObjectResponse<Domain>> {
    return this.httpClient.post<ObjectResponse<Domain>>(`/api/v1/domain/`, { hostname });
  }
}