import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { SideMenuComponent } from './components/side-menu/side-menu.component';

@Component({
  selector: 'app-loggedin',
  templateUrl: './loggedin.component.html',
  styleUrls: ['./loggedin.component.scss']
})
export class LoggedinComponent implements OnInit {

  @ViewChild(SideMenuComponent) sideMenu: SideMenuComponent;

  constructor(public authService: AuthService) { }

  ngOnInit(): void {
  }

  logoff() {
    this.authService.logout();
  }
}
