import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ObjectResponse } from 'src/app/models/ObjectResponse';
import { RedirectionModel } from '../../models/Redirection';
import { RedirectionHttpService } from '../../services/redirection-http-service';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-redirection-create-dialog',
  templateUrl: './redirection-create-dialog.component.html',
  styleUrls: ['./redirection-create-dialog.component.scss']
})
export class RedirectionCreateDialogComponent implements OnInit {
  response: ObjectResponse<RedirectionModel>;
  domainId: number;
  redirection: RedirectionModel;

  form = new UntypedFormGroup({
    name: new UntypedFormControl(),
    code: new UntypedFormControl(),
    target: new UntypedFormControl()
  });

  constructor(
    private dialog: MatDialogRef<RedirectionCreateDialogComponent>,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: RedirectionHttpService) {
      this.domainId = data.id;
      this.redirection = data.redirection;
     }

  ngOnInit(): void {
    this.setData();
  }

  setData()
  {
    if(this.redirection === undefined)
    {
      return;
    }

    this.form.controls.name.setValue(this.redirection.name);
    this.form.controls.code.setValue(this.redirection.code);
    this.form.controls.target.setValue(this.redirection.target);
  }

  submit()
  {
    let redirection = new RedirectionModel();
    redirection.code = this.form.controls.code.value;
    redirection.name = this.form.controls.name.value;
    redirection.target = this.form.controls.target.value;

    if(this.redirection !== undefined)
    {
      this.service.edit(this.domainId, this.redirection.id, redirection).subscribe((success) => {
        this.snackBar.open("Redirection updated", "", {
          duration: 3000
        });
        this.dialog.close();
      }, (error) => {
        this.response = error;
      })
    }
    else 
    {
      this.service.create(this.domainId, redirection).subscribe((success) => {
        this.snackBar.open("Redirection created", "", {
          duration: 3000
        });
        this.dialog.close();
      }, (error) => {
        this.response = error;
      });
    }

    return false;
  }

  close()
  {
    this.dialog.close();
  }
}
