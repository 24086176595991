<div *ngIf="!loading">
  <p><strong>Reset password</strong></p>

  <div *ngIf="!found">
    <div class="alert alert-danger">
      <strong>Error:</strong><br />
      Invalid reset link
    </div>
  </div>

  <div *ngIf="found">

    <div class="alert alert-danger" *ngIf="error !== undefined && error !== null">
      <strong>Error:</strong><br />
      Invalid input
    </div>

  <div class="alert alert-success" *ngIf="success && submitted">
    <strong>Success</strong><br>Password reset.
  </div>

    <form [formGroup]="form" (ngSubmit)="reset()">
      <div class="form-group" *ngIf="!success">
        <label>Email:</label>
        <input type="email" class="form-control full-width" value="{{email}}" disabled />
      </div>
      <div class="form-group" *ngIf="!success">
        <label>Password:</label>
        <input type="password" class="form-control full-width" formControlName="password" />
        <div *ngIf="form.controls['password'].invalid && (form.controls['password'].dirty || form.controls['password'].touched)" class="control-error">
          <ul>
            <li *ngFor="let error of form.controls['password'].errors | objToKeys">
              {{ error}}
            </li>
          </ul>
        </div>
      </div>
      <div class="form-group" *ngIf="!success">
        <label>Password confirmation:</label>
        <input type="password" formControlName="passwordConf" class="form-control" />
        <div *ngIf="form.controls['passwordConf'].invalid && (form.controls['passwordConf'].dirty || form.controls['passwordConf'].touched)" class="control-error">
          <ul>
            <li *ngFor="let error of form.controls['passwordConf'].errors | objToKeys">
              {{ error }}
            </li>
          </ul>
        </div>
      </div>
      <div class="form-group">
        <button class="btn btn-primary btn-full-width" [disabled]="form.invalid"  *ngIf="!success">Reset</button><br />
      </div>
    </form>
  </div>
  <a href="/auth/login">Back</a>
</div>
