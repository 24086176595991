import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ObjectResponse } from 'src/app/models/ObjectResponse';
import { ListResponse } from 'src/app/models/list-response';
import { RedirectionModel } from '../models/Redirection';

@Injectable({
  providedIn: 'root',
})
export class RedirectionHttpService {
  constructor(private httpClient: HttpClient) {}

  list(domainId: number, take, skip): Observable<ListResponse<RedirectionModel>> {
    return this.httpClient.get<ListResponse<RedirectionModel>>(`/api/v1/domain/${domainId}/redirection?take=${take}&skip=${skip}`);
  }

  get(domainId: number, id: number): Observable<ObjectResponse<RedirectionModel>> {
    return this.httpClient.get<ObjectResponse<RedirectionModel>>(`/api/v1/domain/${domainId}/redirection/${id}`, { });
  }
  
  delete(domainId: number, id: number): Observable<Response> {
    return this.httpClient.delete<Response>(`/api/v1/domain/${domainId}/redirection/${id}`, { });
  }

  edit(domainId: number, id: number, redirection: RedirectionModel): Observable<Response> {
    return this.httpClient.patch<Response>(`/api/v1/domain/${domainId}/redirection/${id}`, redirection);
  }

  create(domainId: number, redirection: RedirectionModel): Observable<ObjectResponse<RedirectionModel>> {
    return this.httpClient.post<ObjectResponse<RedirectionModel>>(`/api/v1/domain/${domainId}/redirection`, redirection);
  }
}