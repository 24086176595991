<h2>Domains</h2>
<div class="btn btn-success" (click)="create()">Create domain</div><br /><br />
<ng-container *ngIf="response !== undefined">
    <div class="table-responsive">
        <table class="table table-striped table-sm">
          <thead>
            <tr>
              <th>#</th>
              <th>Hostname</th>
              <th>Verified</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of response.data" (click)="goTo(item.id)" style="cursor: pointer;">
              <td>{{item.id}}</td>
              <td>{{item.hostname}}</td>
              <td>{{item.verified}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <app-pagination #paging [currentOffset]="response.skip" [total]="response.total" [pageSize]="pageSize" (onSelect)="navigate($event)"></app-pagination>
</ng-container>