import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';
import {
  Injectable
} from '@angular/core';
import {
  Observable, throwError
} from 'rxjs';
import {
  AuthService
} from './auth.service';
import {
  catchError
} from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';



@Injectable()
export class TokenInterceptorService implements HttpInterceptor {
  constructor(private authService: AuthService, private router: Router, private snackbar: MatSnackBar) {}

  intercept(req: HttpRequest < any > , next: HttpHandler): Observable < HttpEvent < any >> {
    const token = this.authService.GetToken();
    let newHeaders = req.headers;
    if (token) {
      newHeaders = newHeaders.append('Authorization', 'Bearer ' + token);
    }

    const authReq = req.clone({
      headers: newHeaders
    });
    return next.handle(authReq)
      .pipe(
        catchError((httpErrorResponse: HttpErrorResponse) => {
          let ref = this.snackbar.open("Loading..");

          // if (httpErrorResponse.status === 504 || httpErrorResponse.status === 503) {
          //   this.snackBar.openError('global.errors.gatewayTimeout', null, 0);
          //   return;
          // }

          // if (httpErrorResponse.status === 500) {
          //   this.snackBar.openError('global.errors.internalServerError', null, 0);

          //   console.log(httpErrorResponse);
          //   return;
          // }

          if (httpErrorResponse.status === 403) {
            this.router.navigate([ '/' ]);
            return;
          }

          if (httpErrorResponse.status === 401) {
            this.authService.logout();
            return;
          }

          ref.dismiss();

          return throwError(httpErrorResponse);
        })
      );
  }
}
