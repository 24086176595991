import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {

  @Input() maxPages = 2;

  @Input() currentOffset: number;
  @Input() total: number;
  @Input() pageSize: number;

  @Output() onSelect: EventEmitter<number> = new EventEmitter<number>();

  currentPage: number;
  pagesShownLeft: number;
  morePagesLeft: boolean;
  pagesShownRight: number;
  morePagesRight: boolean;
  totalPages: number;
  firstPage: boolean;
  lastPage: boolean;

  constructor() { }

  ngOnInit() {
    this.setPageNum(this.currentOffset);
  }

  navigate(page: number)
  {
    this.onSelect.emit(page);
  }

  setPageNum(offset: number)
  {
    this.currentOffset = offset;

    this.totalPages = Math.ceil(this.total / this.pageSize) - 1;
    this.currentPage = Math.floor(this.currentOffset / this.pageSize);

    this.firstPage = offset === 0;
    this.lastPage = this.currentPage === this.totalPages;

    const pagesRight = this.totalPages - this.currentPage;

    this.pagesShownLeft = this.currentPage > this.maxPages ? this.maxPages : this.currentPage;
    this.morePagesLeft = this.pagesShownLeft !== this.currentPage;

    this.pagesShownRight = pagesRight > this.maxPages ? this.maxPages : pagesRight;
    this.pagesShownRight = this.pagesShownRight >= 0 ? this.pagesShownRight: 0;
    this.morePagesRight = this.pagesShownRight !== pagesRight;
  }

  navigatePrev()
  {
    if (!this.firstPage)
    {
      this.navigate(this.currentPage - 1);
    }
  }

  navigateNext()
  {
    if (!this.lastPage)
    {
      this.navigate(this.currentPage + 1);
    }
  }

}
