<div class="content">
  <h1 class="h3 mb-3 font-weight-normal" style="text-align: center"> Sign in</h1>

  <div class="alert alert-danger" *ngIf="failed">
    <strong>Error:</strong><br />
    Invalid password
  </div>
  <form [formGroup]="form" (ngSubmit)="login()">
    <div class="form-group">
      <label>Email:</label>
      <input type="text" class="form-control full-width" formControlName="username" />
    </div>
    <div class="form-group">
      <label>Password:</label>
      <input type="password" class="form-control full-width" formControlName="password" />
    </div>
    <div class="form-group">
      <button class="btn btn-primary btn-full-width" [disabled]="form.invalid">Login</button><br>
      <a [routerLink]="[ '/auth/forgot-password' ]">Forgot password</a><br>
    </div>
  </form>
</div>